import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-multiselect-schedule',
  templateUrl: './multiselect-schedule.component.html',
  styleUrls: ['./multiselect-schedule.component.scss']
})
export class MultiselectScheduleComponent implements OnInit, OnDestroy {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todos',
    unSelectAllText: 'Todos',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };
  url: string;
  public idGroup: any;
  rol;
  idGroup$: Subscription;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getSchedules();
      }
    });
  }

  ngOnInit(): void {
    this.getSchedules();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getSchedules() {
    const items = [];
    this.gasprecioService.getSchedules(this.idGroup).subscribe((data: any) => {
      const reasons: any = data;
      reasons.forEach((element: any) => {
        items.push({ item_id: element._id, item_text: 'Horario ' + element['num_schedule'], numSchedule: element['num_schedule'] });
      });

      this.dropdownList = items;
    });
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'schedule_ids[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementSchedules = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'schedule_ids[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementSchedules = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementSchedules = this.url;
  }

}
