<div class="card" style="height:960px !important;">
    <div class="card-body" style="padding: .75rem 1.25rem !important">
        <div class="dataTables_wrapper">
            <h5 style="display: inline-block;">
                Comparativo Rentabilidad
            </h5>

            <div class="select-type" *ngIf="rol === 'Dios'"
                [ngStyle]="{ 'display': reqst === 2 ? 'inline-block' : 'none' }">
                <app-select-group></app-select-group>
            </div>

            <div class="select-type" [ngStyle]="{ 'display': reqst === 2 ? 'inline-block' : 'none' }">
                <app-select-product-total [defaultProductType]="'total'"></app-select-product-total>
            </div>

            <div class="select-type" [ngStyle]="{ 'display': reqst === 2 ? 'inline-block' : 'none' }">
                <app-select-variable-profitability></app-select-variable-profitability>
            </div>

            <div class="select-type" [ngStyle]="{ 'display': reqst === 2 ? 'inline-block' : 'none' }">
                <app-select-profitability-percent></app-select-profitability-percent>
            </div>

            <div class="flex-item" style="display: flex;">
                <div class="comparison">
                    <div class="row" style="margin:10px;" *ngIf="reqst === 1">
                        <app-table-loader style="width: 100%;"></app-table-loader>
                    </div>
                    
                    <ngx-datatable *ngIf="reqst === 2" class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                        [rowHeight]="getRowHeight" [rows]="temp" [scrollbarV]="true" [scrollbarH]="true" [selected]="selected"
                        [selectionType]="SelectionType.multiClick" style="height: auto;margin-top: 16px;"
                        [style.min-height]="heightTable + 'px'"
                        [messages]="{emptyMessage: 'No se ha encontrado ninguna estación con tu búsqueda, por favor intenta con otro parámetro.'}">
                    
                        <ngx-datatable-column prop="name" [width]="110" [frozenLeft]="true" [sortable]="false"
                            [cellClass]="'left-side green-blue'" [headerClass]="'left-side top-side green-blue'">
                            <ng-template ngx-datatable-header-template>
                                <span> </span>
                            </ng-template>
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="title-l">
                                    <span style="margin-left: 8px;">
                                        {{row.name}}
                                    </span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    
                        <ngx-datatable-column [width]="90" *ngFor="let column of columns; let i = index;let lasty = last"
                            name="{{column.name}}" [sortable]="false" prop="{{'day_' + column.index}}"
                            [headerClass]="!lasty ? 'top-side': 'top-side right-side'" [cellClass]="getCellClass">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{row['day_' + i]}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                    
                    <div *ngIf="reqst === 1" class="text-center loader-container">
                        <div class="spinner-grow align-middle text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    
                    <div class="flex-item" *ngIf="reqst===2" style="width: 100%;">
                        <div class="graphic">
                            <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
                                style="width: 100%; height: 700px; display: block;"></highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

