import { Component, Input, OnDestroy } from "@angular/core";
import { combineLatest, Subject, Subscription, takeUntil } from "rxjs";
import { GasprecioService } from "../../../gasprecio/services/gasprecio.service";
import { SharedService } from "../../../gasprecio/services/shared.service";
import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from "@angular/router";
import Highcharts from "highcharts";
import { GroupIds } from "src/app/modules/gasprecio/enums/groups-ids.enum";
import moment from "moment";

@Component({
  selector: "app-heat-map-by-month",
  templateUrl: "./heat-map-by-month.component.html",
  styleUrls: ["./heat-map-by-month.component.scss"],
})
export class HeatMapByMonthComponent implements OnDestroy {
  
  @Input() station: any = false;
  variable = "liters";
  chartOptions: Highcharts.Options;
  data = [];
  date = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
  event$!: Subscription;
  Highcharts = Highcharts;
  hours = [];
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = "regular";
  period = "day";
  reqst = 0;
  rol;
  route;
  stationId: any;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem("rol");
    this.idGroup = this.rol === "Dios" ? localStorage.getItem("group") || GroupIds.Pa : localStorage.getItem("group");
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([idGroup, zone, stationId, period]) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }

        if (this.zone !== zone) {
          if (this.zone !== undefined) {
            this.changeZone = true;
          }
          this.zone = zone;
        }

        if (this.stationId !== stationId) {
          this.stationId = stationId;
        }

        if (period !== this.date) {
          this.date = period;
        }

        if (!this.loading) {
          this.getHeatMapMonthly();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateChartData = (data) => {
    const firstWeekday = moment(this.date).day(); // Obtén el primer día de la semana
    const emptyTilesFirst = firstWeekday;
    const chartData = [];

    for (let day = 1; day <= data.length; day++) {
      const date = data[day - 1].date;
      const xCoordinate = (emptyTilesFirst + day - 1) % 7;
      const yCoordinate = Math.floor((firstWeekday + day - 1) / 7);
      const value = Math.floor(data[day - 1].value);
      const min = this.gasprecioService.getArrRange(data, 'min', 'object');
      const max = this.gasprecioService.getArrRange(data, 'max', 'object');
      if (value !== null) {
        chartData.push({
          x: xCoordinate,
          y: 5 - yCoordinate,
          value: value,
          date: moment(date).valueOf(),
          custom: { monthDay: day },
          color: this.gasprecioService.changeColor(value, min, max)
        });
      }
    }

    return chartData;
  };

  getHeatMapMonthly() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    this.hours = [];
    setTimeout(() => {
      const url = this.station ? this.gasprecioService.getHeatMapMonthlyByStation(this.idGroup, this.variable, this.date ) : 
      this.stationId === 'zone' ? this.gasprecioService.getHeatMapMonthly(this.idGroup, this.zone, this.variable, this.date, true) : 
      this.gasprecioService.getHeatMapMonthly(this.idGroup, this.stationId, this.variable, this.date);
      url.subscribe({
        next: (data: any) => {
          this.data = this.generateChartData(data["data_formatted"]);
          this.setData();
        },
        complete: () => {
          this.reqst = 1;
        },
        error: () => {
          this.loading = false;
          this.reqst = 2;
        },
      });
    }, 400);
  }

  setData() {
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      chart: { 
        type: "heatmap",
        spacingTop: 30,
        spacingRight: 20,
        spacingBottom: 0,
        spacingLeft: 0
      },
      title: { 
        text: "Ventas por Día",   
        style: { fontSize: '0' } 
      },
      exporting: {
        filename:  `Ventas por Día`,
        sourceWidth: 1200,
        sourceHeight: 800,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          y: -32
        }
      },
      xAxis: {
        categories: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ],
        opposite: true,
        lineWidth: 26,
        offset: 13,
        lineColor: "rgba(27, 26, 37, 0.1)",
        labels: {
          rotation: 0,
          y: 20,
          style: { fontWeight: "bold" },
        },
      },
      yAxis: {
        min: 0,
        max: 5,
        visible: false,
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, "#99FF99"],
          [0.2, "#99FF99"],
          [0.4, "#FFFF99"],
          [0.6, "#FFCC99"],
          [0.8, "#FF9966"],
          [1, "#FF6666"],
        ],
        labels: { format: "{value} lts." },
      },
      tooltip: {
        enabled: true,
        outside: true,
        headerFormat: "",
        pointFormat: `{point.date: %e de %b del %Y}: <b>{point.value} lts.</b>`,
        nullFormat: "",
      },
      legend: {
        enabled: false
      },
      series: [
        {
          type: "heatmap",
          data: this.data,
          nullColor: "rgba(196, 196, 196, 0.2)",
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 1)',
          dataLabels: [
            {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.point.value, 0, ".", ",");
              },
              style: {
                textOutline: "none",
                fontWeight: "normal",
                fontSize: "1rem",
              },
              y: 4,
            },
            {
              enabled: true,
              align: "left",
              verticalAlign: "top",
              format: "{point.custom.monthDay}",
              style: {
                textOutline: "none",
                color: "black",
                fontSize: "0.6rem",
                fontWeight: "bold",
              },
              x: 1,
              y: 1,
            },
          ],
        },
      ],
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
