import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StationService } from 'src/app/modules/station/services/station.service';
import { ClickService } from 'src/app/services/click.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() pageSelection: string;
  @Output() toggle: EventEmitter<null> = new EventEmitter();

  alerts: any[] = [];
  brands: any[] = [];
  clienteActual: string;
  clientsList: any[] = [];
  defaultZone = '';
  email: string;
  filterText: string;
  id: string;
  idGroup: string | GroupIds;
  idStation: string;
  isMobile = false;
  letters: string;
  name: string;
  notifications = false;
  page$: Observable<string>;
  page: string;
  rol: string;
  route: string;
  showEnerser = false;
  showRendichicas = false;
  station: any;
  stationList: any[] = [];
  subscriptions: Subscription = new Subscription();

  constructor(
    private gasprecioService: GasprecioService,
    public stationService: StationService,
    private clickService: ClickService,
    private authenticationService: AuthService,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.page$ = sharedService.sharedPage;
    this.isMobile = this.checkIfMobile();
    this.rol = localStorage.getItem('rol') || '';
    this.idStation = localStorage.getItem('idStation') || '';
    this.id = localStorage.getItem('id') || '';
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.showEnerser = this.idGroup === GroupIds.Enerser || this.idGroup === GroupIds.Combuservicio;
    this.showRendichicas = this.idGroup === GroupIds.Rendichicas;
    this.loadBrands();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.sharedService.sharedPage.subscribe(val => this.page = val)
    );
    this.getProfile();
    this.getAlerts();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private checkIfMobile(): boolean {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(details);
  }

  private loadBrands() {
    this.gasprecioService.getBrands(this.idGroup).subscribe(data => {
      this.brands = data.map(element => ({
        name: this.gasprecioService.capitalizeFirstLetter(element.name),
        value: element.id
      }));
    });
  }

  private async getProfile() {
    try {
      const data = await firstValueFrom(this.stationService.getProfile());
      this.processProfileData(data);
      this.getStation();
    } catch (error) {
      console.error('Error fetching profile data', error);
    }
  }

  getStation() {
    this.stationService.getStationProfile(this.idStation).subscribe(
      data => {
        this.station = data.station;
        this.idGroup = this.station.group;
        this.defaultZone = this.station.default_zone;
        localStorage.setItem('idZone', this.station.default_zone);
        this.sharedService.nextZone = this.defaultZone;
        this.showEnerser = this.idGroup === GroupIds.Enerser || this.idGroup === GroupIds.Combuservicio;
        this.showRendichicas = this.idGroup === GroupIds.Rendichicas;
        if (this.idGroup?.length > 0) {
          localStorage.setItem('group', this.station.group);
          this.sharedService.nextGroup = this.station.group;
        }
        if (this.station['custom']['schedule_notifications']) {
          localStorage.setItem('from', this.station['custom']['schedule_notifications']['from']);
          localStorage.setItem('until', this.station['custom']['schedule_notifications']['until']);
        }
      })
  }

  private processProfileData(data: any) {
    localStorage.setItem('rol', data.rol);
    this.rol = data.rol;
    this.clientsList = data.stations;
    this.stationList = data.stations;

    if (this.clientsList.find(c => c._id === this.idStation)) {
      this.clienteActual = this.clientsList.find(c => c._id === this.idStation).alias;
    } else {
      this.clienteActual = this.clientsList[0]?.alias || '';
      this.updateLocalStorageWithFirstClient();
    }

    this.email = data.email;
    this.name = data.name;
    localStorage.setItem('id', data._id);
    this.id = data._id;
    this.idStation = data.idStation;
    this.gasprecioService.setPlayerId(this.id);
    this.letters = this.name.split(' ').map(word => word[0]).slice(0, 2).join('');
  }

  private updateLocalStorageWithFirstClient() {
    const firstClient = this.clientsList[0];
    localStorage.setItem('creId', firstClient.cre);
    localStorage.setItem('idStation', firstClient._id);
    window.location.reload();
  }

  private getAlerts() {
    this.gasprecioService.getAlerts().subscribe((data: any) => {
      this.alerts = data;
      this.notifications = this.alerts.some(element => !element.click);
    });
  }

  openSideBar() {
    this.toggle.emit();
  }

  changePage(page: string) {
    this.page = page;
    this.router.navigate(['/' + page.toLowerCase()]);
  }

  goTo(type: string, alert: any) {
    localStorage.setItem('creId', alert.cre);
    localStorage.setItem('idStation', alert.idStation);
    this.alertRead(alert._id);
    this.router.navigate(['/gasprecio/' + (type === 'price_rules' ? 'reglas-precio' : 'monitor')]).then(() => {
      window.location.reload();
    });
  }

  changeClient(cliente: any, type) {
    this.clienteActual = cliente.alias;
    localStorage.setItem('creId', cliente.cre);
    localStorage.setItem('idStation', cliente._id);
    if(type) {
      window.location.reload();
    } else {
      this.route = 'monitor';
      this.router.navigate(['/gasprecio/monitor']).then(() => {
        window.location.reload();
      });
    }
  }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  alertRead(idAlert: string, status?: string) {
    if (!status) {
      this.gasprecioService.alertRead(idAlert).subscribe(
        () => {
          this.notifications = false;
          this.getAlerts();
        });
    }
  }

  filterItem(str: string) {
    if (typeof str === 'string') {
      this.clientsList = this.stationList.filter((d: any) => d['alias'].toLowerCase().includes(str.toLowerCase()));
    }
  }

  clickIcon(type: string) {
    const page = this.page === 'General' ? 'Tablero' : this.page === 'Mercado' ? 'Modulo Inteligencia' : this.page;

    if (type !== page) {
      this.resetMarketIntelligence();
      this.clickPage(type);
    }
  }

  clickPage(page: string) {
    this.clickService.clickPage(this.id, this.idStation, page, this.page).subscribe(data => {
      console.log(data);
    });
  }

  resetMarketIntelligence() {
    this.sharedService.resetMarketIntelligence();
  }

  capitalizeFirstLetter(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
