import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-profitability-type',
  templateUrl: './select-profitability-type.component.html',
  styleUrls: ['./select-profitability-type.component.scss']
})
export class SelectProfitabilityTypeComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Sin Filtro', value: null },
    { name: 'Top', value: 'top' },
    { name: 'Bottom', value: 'bottom' },
    { name: 'Resto', value: 'rest' }
  ];

  public profitabilityType: any = [];
  public ngxDisabled = false;
  profitabilityType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.profitabilityType$ = this.sharedService.sharedProfitabilityType.subscribe(profitabilityType => {
      if (this.profitabilityType !== profitabilityType) {
        this.profitabilityType = profitabilityType;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.profitabilityType$.unsubscribe();
  }

  changeProfitabilityType(event: any) {
    this.profitabilityType = event;
    this.sharedService.nextProfitabilityType = this.profitabilityType;
  }
}
