import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-chart-transactions',
  templateUrl: './chart-transactions.component.html',
  styleUrls: ['./chart-transactions.component.scss']
})
export class ChartTransactionsComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  idGroup: GroupIds | string = GroupIds.Pa;
  idGroup$!: Subscription;
  importants;
  importants$: Subscription;
  months = 12;
  months$!: Subscription;
  event$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  purchaseMargin;
  reqst = 0;
  route;
  rol;
  showScale = true;
  showRendichicas = false;
  transactions: any;
  volumenDif;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'column'
    },
    title: {
      text: `Volumen de Transacciones (# Cargas) `,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Volumen de Transacciones',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f}',
          style: {
            fontSize: '12px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      column: {
        animation: {
          duration: 1000
        },
        stacking: 'normal'
      }
    },
    exporting: {
      filename: `Volumen de Transacciones (# Cargas) `,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.showRendichicas = this.idGroup === GroupIds.Rendichicas;
    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.showRendichicas = this.idGroup === GroupIds.Rendichicas;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardTransactionsStation();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardTransactionsStation();
        }
      }
    });

    this.importants$ = sharedService.sharedImportants.subscribe((importants: string) => {
      if (this.importants !== importants) {
        this.importants = importants;
        if (this.reqst > 1) {
          this.getDashboardTransactionsStation();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardTransactionsStation();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.importants$.unsubscribe();
    this.event$.unsubscribe();
  }

  getDashboardTransactionsStation() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ? this.gasprecioService.getDashboardTransactionsStation(this.period, typeValue, n) : this.gasprecioService.getDashboardTransactions(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, n);
    setTimeout(() => {
      url.subscribe({
        next: (data: any) => {
          const info = data;
          this.transactions = info['transactions'];
          this.setData();
        }, complete: () => {
          this.reqst = 2;
        }, error: () => {
          this.reqst = 3;
        }
      });
    }, 400);
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorLight = this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);

    const minVolume = Math.min(...this.transactions
      .map((comp: any) => +comp[this.productType])
      .filter((value: number) => value !== null && value !== 0)
    );

    let transactionsChartData: any;
    const borderW = (this.days >= 90) || (this.weeks >= 90) ? .4 : 2;
    if (this.productType === 'total') {
      this.showScale = true;
      const colors = [
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('regular')]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('premium')]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('diesel')])
      ];

      const colorLights = [
        this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex('regular')]),
        this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex('premium')]),
        this.gasprecioService.getColorRGBAString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex('diesel')])
      ];


      const productTypes = ['regular', 'premium', 'diesel'];

      transactionsChartData = productTypes.map((type, index) => ({
        name: this.gasprecioService.capitalizeFirstLetter(type),
        type: 'column',
        data: this.transactions.map((comp: any) => +comp[type]),
        color: colorLights[index],
        borderColor: colors[index],
        borderWidth: borderW,
        yAxis: 0,
        stack: 'total',
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</b>';
          }
        }
      }));
    } else {
      transactionsChartData = [{
        name: 'Volumen de Transacciones',
        type: 'column',
        data: this.transactions.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: colorLight,
        borderColor: color,
        borderWidth: borderW,
        yAxis: 0,
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</b>';
          }
        }
      }];
    }

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.transactions.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['yAxis'][0]['min'] = this.showScale ? 0 : minVolume;
    this.chartOptions.series = transactionsChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas7: any = document.getElementById('mychart7');
    const imgData7 = canvas7.toDataURL('image/png');
    this.sharedService.nextImgData7 = imgData7;
  }
}
