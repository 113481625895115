import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-gasolines',
  templateUrl: './select-product-gasolines.component.html',
  styleUrls: ['./select-product-gasolines.component.scss']
})
export class SelectProductGasolinesComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Total', value: 'total' },
    { name: 'Regular', value: 'regular' },
    { name: 'Premium', value: 'premium' },
    { name: 'Diesel', value: 'diesel' },
    { name: 'Gasolinas', value: 'gasoline' }
  ];

  public productGasoline: any = [];
  public ngxDisabled = false;
  productGasoline$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productGasoline$ = this.sharedService.sharedProductGasoline.subscribe(productGasoline => {
      if (this.productGasoline !== productGasoline) {
        this.productGasoline = productGasoline;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.productGasoline$.unsubscribe();
  }

  changeProductGasoline(event: any) {
    this.productGasoline = event;
    this.sharedService.nextProductGasoline = this.productGasoline;
  }
}
