import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-login-prices',
  templateUrl: './login-prices.component.html',
  styleUrls: ['./login-prices.component.scss']
})
export class LoginPricesComponent implements OnInit {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  token: any;
  idStation: any;
  creId: any;
  rol;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthService
  ) {
    const token = this.authenticationService.getToken();
    const idStation = this.authenticationService.getStation();
    this.token = token ? token : localStorage.getItem('tokenPrices');
    this.idStation = idStation ? idStation : localStorage.getItem('idStationPrices'); 
    if (this.idStation && this.token) {
      this.router.navigate([`/precios/${this.idStation}`]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService.loginPrices(this.f.user.value, this.f.password.value)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.idStation = data['stations'][0];
            this.token = data['token'];
            localStorage.setItem('idStationPrices', this.idStation);
            localStorage.setItem('tokenPrices', this.token);
            this.authenticationService.setToken(this.token);
            this.authenticationService.setStation(this.idStation);
            this.router.navigate([`/precios/${this.idStation}`]);
          }
        },
        error: () => {
          this.errorLogin();
        }
      });
  }

  reloadPage(): void {
    window.location.reload();
  }

  showLoader(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
  }

  errorLogin() {
    Swal.fire({
      title: 'El usuario o la contraseña son inválidos',
      text: 'Intentalo de nuevo',
      icon: 'error',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar'
    });
  }
}
