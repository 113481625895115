import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-purchase-source',
  templateUrl: './select-purchase-source.component.html',
  styleUrls: ['./select-purchase-source.component.scss']
})
export class SelectPurchaseSourceComponent implements OnInit, OnDestroy {

  @Input() showAll = false;
  public items: any[] = [
    { name: 'Ambas Fuentes', value: 'all' },
    { name: 'Compras Archivo Central', value: 'sheet' },
    { name: 'Compras Control Gas', value: 'volumetric' }
  ];

  public purchaseSource: any = [];
  public ngxDisabled = false;
  purchaseSource$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.purchaseSource$ = this.sharedService.sharedPurchaseSource.subscribe(purchaseSource => {
      if (this.purchaseSource !== purchaseSource) {
        this.purchaseSource = purchaseSource;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit() {
    if (this.showAll) {
      this.sharedService.nextPurchaseSource = 'all';
    }
  }

  ngOnDestroy() {
    this.purchaseSource$.unsubscribe();
  }

  changePurchaseSource(event: any) {
    this.purchaseSource = event;
    this.sharedService.nextPurchaseSource = this.purchaseSource;
  }
}
