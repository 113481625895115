import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variable-profitability',
  templateUrl: './select-variable-profitability.component.html',
  styleUrls: ['./select-variable-profitability.component.scss']
})
export class SelectVariableProfitabilityComponent implements OnDestroy {

  public items: any[] = [
    { value: 'sales', variable: 'sales', name: 'Ventas Acum.' },
    { value: 'avg_sales', variable: 'avg_sales', name: 'Ventas Prom.' },
    { value: 'margin', variable: 'margin', name: 'Margen' },
    { value: 'utility', variable: 'utility', name: 'Utilidad Bruta ' },
    { value: 'utility_pct', variable: 'utility_pct', name: '% Utilidad Bruta' }
  ];

  public variableSelected: any = [];
  public ngxDisabled = true;
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariableProfitability.subscribe((variableSelected: string) => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableProfitability = this.variableSelected;
  }

}
