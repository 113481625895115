import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByPL'
})
export class FilterByPLPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any[] {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter(item => {
      // Expresión regular que permite el año entre 2019 y 2024
      const match = item.cre_id.match(/PL\/(\d+)\/EXP\/ES\/(2015|2016|2017|2018|2019|2020|2021|2022|2023|2024)/);
      const numberPart = match ? match[1] : item.cre_id;

      // Comparación con la parte numérica
      return numberPart.toLowerCase().includes(searchTerm);
    });
  }
}
