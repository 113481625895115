import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-multiselect-tiers',
  templateUrl: './multiselect-tiers.component.html',
  styleUrls: ['./multiselect-tiers.component.scss']
})
export class MultiselectTiersComponent implements OnDestroy {

  @Input() isDropdownDisabled = false;
  dropdownList = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };
  idGroup$: Subscription;
  idGroup: any;
  rol;
  loading = false;
  selectedComplementTiers = null;
  selectedComplementTiers$!: Subscription;
  selectedItems = [];
  url: string;
  zone = '6178671b46818a57510e9218';
  zone$!: Subscription;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');

    this.zone$ = sharedService.sharedZone.subscribe((zone: any) => {
      if (zone !== this.zone && !this.loading) {
        this.zone = zone;
        this.sharedService.nextSelectedComplementTiers = [];
        this.getReasons();
      }
    });

    this.selectedComplementTiers$ = this.sharedService.sharedSelectedComplementTiers.subscribe(selectedComplementTiers => {
      if(selectedComplementTiers !== this.selectedComplementTiers) {
        this.selectedItems = [...selectedComplementTiers];
      }
    });
  }

  ngOnDestroy() {
    this.zone$.unsubscribe(); 
  }

  getReasons() {
    const items = [];
    if(this.zone === null) {
      this.zone = '6178671b46818a57510e9218';
    }
    this.loading = true;
    this.gasprecioService.getTiers(this.idGroup, this.zone).subscribe((data: any) => {
      const tiers: any = data;
      tiers.forEach((element: any) => {
        items.push({ item_id: element.value, item_text: element.name });
      });
      if (this.selectedItems.length === 0) {
        this.selectedItems = [{ item_id: 1, item_text: 'Tier 1' }];
      }
      this.dropdownList = items;
      this.loading = false;
    });

  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'tiers[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementTiers = this.url;
    this.sharedService.nextSelectedComplementTiers = this.selectedItems;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'tiers[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementTiers = this.url;
    this.sharedService.nextSelectedComplementClusters = this.selectedItems;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementTiers = this.url;
    this.sharedService.nextSelectedComplementClusters = this.selectedItems;
  }

}
