<div class="header-c">
    Precio de Venta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Precio de venta promedio diario reportado a la CRE por estación.
    </epy-tooltip>
    - Precio de Compra
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Precio de compra promedio diario calculado según las pipas compradas y valor de inventario de cada estación.
    </epy-tooltip>
    &nbsp;
    <input type="checkbox" id="checkbox-i" [(ngModel)]="showYAxis" (ngModelChange)="setData()"> <label
        style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-i">Ver en sólo 1 eje</label>
    &nbsp;
    <input type="checkbox" id="checkbox-price-x" [(ngModel)]="isAnimationEnabled" (ngModelChange)="setData()"
        *ngIf="showEnerser"> <label style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-price-x"
        *ngIf="showEnerser">Ver cifras</label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div 
[ngClass]="{
  'flex-item': ((period === 'week' && (weeks === 8 || weeks === 12)) || period === 'month' || (period === 'day' && (days === 15 || days === 30))) && !station,
  'flex-right': !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) && period !== 'month' || station
}"
    *ngIf="reqst===2">
    <div>
        <highcharts-chart id="mychart1" [Highcharts]="Highcharts" [options]="chartOptions"
            [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>