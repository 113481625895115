import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-type-sales',
  templateUrl: './select-product-type-sales.component.html',
  styleUrls: ['./select-product-type-sales.component.scss']
})
export class SelectProductTypeSalesComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Prods. y Total', value: 'all' },
    { name: 'Productos', value: 'products' },
    { name: 'Total', value: 'total' },
    { name: 'Gasolina vs Diesel', value: 'gasoline_diesel' }

  ];

  public productTypeSales: any = [];
  public ngxDisabled = false;
  productTypeSales$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeSales$ = this.sharedService.sharedProductTypeSales.subscribe(productTypeSales => {
      if (this.productTypeSales !== productTypeSales) {
        this.productTypeSales = productTypeSales;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.productTypeSales$.unsubscribe();
  }

  changeProductTypeSales(event: any) {
    this.productTypeSales = event;
    this.sharedService.nextProductTypeSales = this.productTypeSales;
  }
}
