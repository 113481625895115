import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-selling-price-chart',
  templateUrl: './selling-price-chart.component.html',
  styleUrls: ['./selling-price-chart.component.scss']
})
export class SellingPriceChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @ViewChild('chartModal') chartModal: TemplateRef<any>;
  @Input() filterDays: any = false;
  @Input() section: any = false;
  changeModal$!: Subscription;
  changeModal;
  days = 180;
  days$!: Subscription;
  dataEvol;
  ppc;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  reqstEvol = 0;
  rol;
  sellingPrice: any;
  stationId$!: Subscription;
  stationId: any;
  nameStation;
  listDemo = ['650b1770c61e9acd325c8df8', '650b1771c61e9acd325c8e18', '650b1772c61e9acd325c8e38', '650b1773c61e9acd325c8e58', '650b1774c61e9acd325c8e78']
  chartEvolOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Histórico - Evolución de Precios`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '$ {value:.2f}',
        style: {
          fontSize: '12px'
        }
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        let tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        // eslint-disable-next-line no-var, @typescript-eslint/no-this-alias
        var currentPoint = this;
        const addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Histórico - Evolución de Precios`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio de Venta - Precio de Competencia`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio de Competencia',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '12px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Precio de Venta - Precio de Competencia`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.rol = localStorage.getItem('rol');
    this.stationId = localStorage.getItem('idStation');
    if (this.listDemo.includes(this.stationId)) {
      this.rol = 'Gaspre';
    }
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartSellingPrice();
      }
    });

    this.days$ = this.sharedService.sharedDaysOptimumPrice.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getChartSellingPrice();
        }
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartSellingPrice();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
    this.days$.unsubscribe();
  }

  getChartSellingPrice() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }

    const url = this.filterDays ? this.gasprecioService.getChartSellingPrice(this.stationId, this.days) : this.gasprecioService.getChartSellingPrice(this.stationId, 30);

    url.subscribe({
      next: (data: any) => {
        this.sellingPrice = data['record'];
        this.ppc = data['ppc'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorOrange = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[4]);
    const sellingPriceChartData: any = {
      name: 'Precio de Venta',
      data: this.sellingPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: color,
      lineWidth: 2,
      marker: { radius: 2 },
      cursor: 'pointer',
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': $ ' + this.y.toFixed(2) + '</b><br>Click aquí para ver el detalle del día';
        }
      },
      events: {
        click: (event) => {
          this.chartNClicked(event['point']['index'], 'Precio de Venta')
        }
      }
    };

    const competitorsPriceChartData: any = {
      name: 'Precio de Competencia',
      data: this.ppc.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorOrange,
      lineWidth: 2,
      cursor: 'pointer',
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': $ ' + this.y.toFixed(2) + '</b><br>Click aquí para ver el detalle del día';
        }
      },
      events: {
        click: (event) => {
          this.chartNClicked(event['point']['index'], 'Precio Competencia')
        }
      }
    };

    const lineXaxisLegend = this.ppc.map((v: any) => this.gasprecioService.parseDateDM(v.date));
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series = [sellingPriceChartData, competitorsPriceChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  getEvolution() {
    this.reqstEvol = 1;
    this.stationId = this.section ? localStorage.getItem('idStation') : this.stationId;
    if (!this.stationId) return;
    this.gasprecioService.getEvolPriceHistory(this.stationId).subscribe({
      next: (res) => {
        this.dataEvol = res;
        this.nameStation = this.dataEvol['client']['name'];
        this.setDataEvol();
      }, complete: () => {
        this.reqstEvol = 2;
      }, error: () => {
        this.reqstEvol = 3;
      }
    });
  }

  setDataEvol() {
    this.reqst = 1;
    const lineChartData: any = [
      {
        name: this.rol !== 'Gaspre' ? this.dataEvol['client']['name'] + ' (' + this.dataEvol['client']['brand'] + ')' : 'Tu Estación ' + ' (' + this.dataEvol['client']['brand'] + ')',
        data: this.dataEvol['client']['values'].map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: 'rgb(212, 175, 55)',
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      }
    ]

    const lineXaxisLegend: any = [];
    this.dataEvol['client']['values'].map((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.dataEvol['competitors'].forEach((c, index) => {
      const color = `rgb(${this.gasprecioService.colors[index + 1].r}, ${this.gasprecioService.colors[index + 1].g}, ${this.gasprecioService.colors[index + 1].b})`;
      lineChartData.push({
        name: this.rol !== 'Gaspre' ? c['name'] + ' (' + c['brand'] + ')' : 'Competitor ' + (index + 1) + ' (' + c['brand'] + ')',
        data: c['values'].filter((type: any) => type !== null).map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: color
      });
    })

    this.chartEvolOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartEvolOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  chartNClicked(index: any, label: string) {
    const date = this.sellingPrice[index].date;
    const name = this.sellingPrice[index].name;
    if (label === 'Precio Competencia') {
      this.getEvolution();
      this.openModalChart();
    } else {
      this.changeDataTable(date, name);
      this.openModal();
    }
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('America/Mexico_City').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }

  openModalChart() {
    this.modalService.open(this.chartModal, { windowClass: 'chartModal', centered: true });
  }

  closeModalChart() {
    this.modalService.dismissAll(this.chartModal);
  }
}
