import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-gasoline',
  templateUrl: './select-product-gasoline.component.html',
  styleUrls: ['./select-product-gasoline.component.scss']
})
export class SelectProductGasolineComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Todos', value: 'all' },
    { name: 'Gasolinas', value: 'gasoline' },
    { name: 'Diesel', value: 'diesel' }
  ];

  public productTypeGasoline: any = [];
  public ngxDisabled = false;
  productTypeGasoline$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeGasoline$ = this.sharedService.sharedProductTypeGasoline.subscribe(productTypeGasoline => {
      if (this.productTypeGasoline !== productTypeGasoline) {
        this.productTypeGasoline = productTypeGasoline;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.productTypeGasoline$.unsubscribe();
  }

  changeProductType(event: any) {
    this.productTypeGasoline = event;
    this.sharedService.nextProductTypeGasoline = this.productTypeGasoline;
  }
}
