import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-profitability-percent',
  templateUrl: './select-profitability-percent.component.html',
  styleUrls: ['./select-profitability-percent.component.scss']
})
export class SelectProfitabilityPercentComponent implements OnDestroy {

  public items: any[] = [
    { name: '10%', value: 10 },
    { name: '15%', value: 15 },
    { name: '20%', value: 20 },
    { name: '25%', value: 25 }
  ];

  public profitabilityPercent: any = [{ name: '15%', value: 15 }];
  public ngxDisabled = false;
  profitabilityPercent$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.profitabilityPercent$ = this.sharedService.sharedProfitabilityPercent.subscribe(profitabilityPercent => {
      if (this.profitabilityPercent !== profitabilityPercent) {
        this.profitabilityPercent = profitabilityPercent;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.profitabilityPercent$.unsubscribe();
  }

  changeProfitabilityPercent(event: any) {
    this.profitabilityPercent = event;
    this.sharedService.nextProfitabilityPercent = this.profitabilityPercent;
  }
}
