import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-cluster',
  templateUrl: './select-cluster.component.html',
  styleUrls: ['./select-cluster.component.scss']
})
export class SelectClusterComponent implements OnDestroy {
  
  @Input() dashboard: any;
  @Input() name: any = 'Imagén';
  private unsubscribe$ = new Subject<void>();
  first = true;
  idGroup;
  idZone = '';
  loading = false;
  public cluster: any = [];
  public items: any[] = [{ name: 'Sin Filtro', value: null }];
  public ngxDisabled = true;
  rol;
  selectedClusters;
  showMegagas = false;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = this.rol === 'Dios'
      ? localStorage.getItem('group') || GroupIds.Pa
      : localStorage.getItem('group');
    this.showMegagas = this.idGroup === GroupIds.Megagas;

    this.sharedService.sharedGroup
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((idGroup: string) => {
        if (idGroup !== this.idGroup) {
          this.first = true;
          this.idGroup = idGroup;
          this.idZone = '';
          this.loadClusters();
        }
      });

    this.sharedService.sharedZoneDashboard
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((zone: any) => {
        if (zone !== this.idZone && this.dashboard) {
          this.idZone = zone;
          if (this.showMegagas) {
            this.sharedService.setNextSelectedClusters(ClusterType.Cluster1, '');
          }
          this.loadClusters();
        }
      });

    this.sharedService.sharedZone
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((zone: any) => {
        if (zone !== this.idZone && !this.dashboard) {
          this.idZone = zone;
          if (this.showMegagas) {
            this.sharedService.setNextSelectedClusters(ClusterType.Cluster1, '');
          }
          this.loadClusters();
        }
      });

    this.sharedService.getSharedSelectedClusters(ClusterType.Cluster1)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedClusters => {
        this.selectedClusters = selectedClusters;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadClusters() {
    this.items = [{ name: 'Sin Filtro', value: null }];
    this.loading = true;
    let selected;
    if(!this.showMegagas) {
      selected = this.selectedClusters;
    }
    this.gasprecioService.getClusters(this.idGroup, 'clusters', this.idZone)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: any) => {
          this.items.push(...data.map((group) => ({
            name: group['name'],
            value: group['_id']
          })));

          if (selected && !this.showMegagas) {
            this.selectedClusters = selected;
          }
        },
        complete: () => {
          this.ngxDisabled = false;
          this.loading = false;
        }
      });
  }

  changeCluster(event: any) {
    this.sharedService.setNextSelectedClusters(ClusterType.Cluster1, event);
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }
}
